export type CancellationPolicy = {
    id: CancellationPolicyId;
    displayName: string;
    fiftyPercentRefundDeadlineDays: number;
    noRefundDeadlineDays: number;
    paymentDeadlineDays: number;
    isActive: boolean;
};

export type CancellationPolicyId = 'LEGACY' | 'FLEXIBLE' | 'MODERATE' | 'STRICT';

export const createCancellationPolicies = (data: Record<string, unknown>[]): CancellationPolicy[] =>
    data.map(
        (policy): CancellationPolicy => ({
            id: policy.id as CancellationPolicyId,
            displayName: policy.displayName as string,
            fiftyPercentRefundDeadlineDays: policy.fiftyPercentRefundDeadlineDays as number,
            noRefundDeadlineDays: policy.noRefundDeadlineDays as number,
            paymentDeadlineDays: policy.paymentDeadlineDays as number,
            isActive: policy.isActive as boolean,
        }),
    );

export const createCancellationPolicy = (data: Record<string, unknown>): CancellationPolicy => {
    const cancellationPolicy: Partial<CancellationPolicy> = {
        id: data.id ? (data.id as CancellationPolicyId) : undefined,
        displayName: data.displayName ? (data.displayName as string) : undefined,
        fiftyPercentRefundDeadlineDays: data.fiftyPercentRefundDeadlineDays ? Number(data.fiftyPercentRefundDeadlineDays) : undefined,
        noRefundDeadlineDays: data.noRefundDeadlineDays ? Number(data.noRefundDeadlineDays) : undefined,
        paymentDeadlineDays: data.paymentDeadlineDays ? Number(data.paymentDeadlineDays) : undefined,
        isActive: data.isActive ? Boolean(data.isActive) : undefined,
    };
    return {
        ...(cancellationPolicy as CancellationPolicy),
    };
};
